<!--
 * @Description: 提交失败
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-01 10:47:40
 * @LastEditors: PSG
 * @LastEditTime: 2021-06-01 11:12:37
-->
<template>
  <div class="success">
    <div class="wrapper">
      <img class='icon' src="@/assets/images/failure.png" alt="">
      <p class="text">提交失败</p>
      <p class="tips">使用文本样式，你可以存储一组样式，并在设计中的不同文本层之间重复使用它们。</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scope>
.success {
  .wrapper {
    margin-top: 120px;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #DC0000;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
    }
  }
}
</style>